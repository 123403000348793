#table-of-contents {
    width: 55%;
}
.toc-entry {
    margin-top: 0;
    margin-left: 1rem;

}

#toc, #toc ul {
    width: 100%;
}

.toc-h1 {
    font-size: 1rem;
}

.toc-h2 {
    font-size: 1rem;
}

.toc-h3 {
    font-size: 0.9rem;
}

.read-more {
    margin-top: 1rem;
    margin-left: 1rem;
    letter-spacing: 0.023em;
}