/* This file contains all the constants for colors and font styles */


$body-font:  "mr-eaves-sans", "Gill Sans", "Gill Sans MT", "Lato", Calibri, sans-serif;
// Note that Gill Sans is the top of the stack and corresponds to what is used in Tufte's books
// However, it is not a free font, so if it is not present on the computer that is viewing the webpage
// The free Google 'Lato' font is used instead. It is similar.
$sans-font:  "mr-eaves-modern", "Gill Sans", "Gill Sans MT", "Lato", Calibri, sans-serif;
$code-font: Consolas, "Liberation Mono", Menlo, Courier, monospace;
$url-font: "Lucida Console", "Lucida Sans Typewriter", Monaco, "Bitstream Vera Sans Mono", monospace;
$text-color: #f2ede3;
$bg-color: #092c44;
$contrast-color: #e6faff;
$border-color: #333333;
$link-style: underline; // choices are 'color' or 'underline'. Default is color using $contrast-color set above

$on-palm:          600px;
$on-laptop:        900px;
$on-wide-screen:   1200px;
